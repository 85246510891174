import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { API_BASE_URL } from '../config';

function FollowLists() {
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const fetchFollowLists = async () => {
    if (!user) {
      setError('Please log in to view your follow lists');
      setLoading(false);
      return;
    }
    try {
      const response = await fetch(`${API_BASE_URL}/follow-lists`, {
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Failed to fetch follow lists');
      }
      const data = await response.json();
      setFollowers(data.followers);
      setFollowing(data.following);
      setError(null);
    } catch (err) {
      console.error('Error fetching follow lists:', err);
      setError(err.message || 'An error occurred while fetching follow lists');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFollowLists();
  }, [user]);

  const handleUnfollow = async (fullUsername) => {
    try {
      const response = await fetch(`${API_BASE_URL}/unfollow`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ fullUsername }),
      });

      if (response.ok) {
        // Refresh the follow lists after unfollowing
        fetchFollowLists();
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to unfollow user');
      }
    } catch (error) {
      setError('An error occurred while unfollowing the user');
    }
  };

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error) return <div className="text-center py-4 text-red-500">Error: {error}</div>;

  return (
    <div className="max-w-4xl mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Your Follow Lists</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-xl font-semibold mb-2">Followers ({followers.length})</h3>
          <ul className="bg-white rounded-lg shadow overflow-hidden">
            {followers.map(follower => (
              <li key={follower.id} className="border-b last:border-b-0 p-4 hover:bg-gray-50">
                {follower.fullUsername}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-2">Following ({following.length})</h3>
          <ul className="bg-white rounded-lg shadow overflow-hidden">
            {following.map(followedUser => (
              <li key={followedUser.id} className="border-b last:border-b-0 p-4 hover:bg-gray-50 flex justify-between items-center">
                <span>{followedUser.fullUsername}</span>
                {followedUser.fullUsername != user.fullUsername && (
                  <button
                    onClick={() => handleUnfollow(followedUser.fullUsername)}
                    className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded text-sm"
                  >
                    Unfollow
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FollowLists;