import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import ContentCard from './ContentCard';
import ProfileEditForm from './ProfileEditForm';
import { API_BASE_URL } from '../config';
import EmailSummary from './EmailSummary';

function ProfilePage() {
  const { user } = useAuth();
  const [userPosts, setUserPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profileInfo, setProfileInfo] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const [postsResponse, profileResponse] = await Promise.all([
          fetch(`${API_BASE_URL}/users/${user.username}/posts`, { credentials: 'include' }),
          fetch(`${API_BASE_URL}/users/${user.username}`, { credentials: 'include' })
        ]);

        if (!postsResponse.ok || !profileResponse.ok) {
          throw new Error('Failed to fetch user data');
        }

        const [posts, profile] = await Promise.all([
          postsResponse.json(),
          profileResponse.json()
        ]);
        console.log('Profile:', profile);
        setUserPosts(posts);
        setProfileInfo(profile);
        setError(null);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError(err.message || 'An error occurred while fetching user data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
    console.log('Profile info:', profileInfo);
  }, [user.username]);

  const handleProfileUpdate = async (updatedInfo) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/${user.username}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(updatedInfo),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      const updatedProfile = await response.json();
      setProfileInfo(updatedProfile);
      setIsEditing(false);
    } catch (err) {
      console.error('Error updating profile:', err);
      setError(err.message || 'An error occurred while updating profile');
    }
  };

  const handlePostUpdate = (updatedPost) => {
    setUserPosts((prevPosts) =>
      prevPosts.map((post) => (post.id === updatedPost.id ? updatedPost : post))
    );
  };

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error) return <div className="text-center py-4 text-red-500">Error: {error}</div>;

  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-2xl">
        <h1 className="text-2xl font-bold mb-4">Profile</h1>
        {isEditing ? (
          <ProfileEditForm
            profileInfo={profileInfo}
            onSave={handleProfileUpdate}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <div className="mb-4">
            {profileInfo.displayName && (
              <h2 className="text-sm text-gray-600">Display Name: {profileInfo.displayName}</h2>
            )}
            {profileInfo.username && (
              <p className="text-sm text-gray-600">Username: {profileInfo.username}</p>
            )}
            {profileInfo.bio && <p className="mt-2">Bio: {profileInfo.bio}</p>}
            {profileInfo.email && (
              <p className="text-sm text-gray-600">Email: {profileInfo.email}</p>
            )}
            <button
              onClick={() => setIsEditing(true)}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Edit Profile
            </button>
          </div>
        )}
        <h2 className="text-xl font-semibold mb-4">Send me a summary of my posts</h2>
        <EmailSummary email={profileInfo.email} user={user} />
        <h2 className="text-xl font-semibold mb-4 mt-8">My Posts</h2>
        <div className="space-y-4">
          {userPosts.map(post => (
            <ContentCard key={post.id} content={post} onUpdate={handlePostUpdate} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;