import React, { useState, useEffect } from 'react';
import LinkPreview from './LinkPreview';
import EditPostModal from './EditPostModal';
import { useAuth } from '../AuthContext';

function ContentCard({ content, onUpdate }) {
  const [likes, setLikes] = useState(content.likes);
  const [showComments, setShowComments] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const [updatedFields, setUpdatedFields] = useState({});
  const { user } = useAuth();

  const isColorLight = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return brightness > 155;
  };

  const getTagColor = (tagName) => {
    let hash = 0;
    for (let i = 0; i < tagName.length; i++) {
      hash = tagName.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 80%)`;
  };

  const handleLike = () => {
    setLikes(likes + 1);
    // TODO: Update likes on the server
  };

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const renderContent = (text) => {
    if (!text) return null;
    return text.split(urlRegex).map((part, index) => 
      urlRegex.test(part) ? <LinkPreview key={index} url={part} /> : part
    );
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handlePostUpdate = (updatedPost, newAddedTags, updatedFields) => {
    onUpdate(updatedPost);
    setIsEditModalOpen(false);
    setNewTags(newAddedTags);
    setUpdatedFields(updatedFields);
    setTimeout(() => {
      setNewTags([]);
      setUpdatedFields({});
    }, 3000); // Remove the effect after 3 seconds
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4 transition duration-300 ease-in-out hover:shadow-lg w-full overflow-hidden">
      <div className="flex items-center mb-4">
        <div
          className="w-10 h-10 rounded-full mr-4 flex items-center justify-center text-xl font-bold"
          style={{ 
            backgroundColor: content.author_avatar_color,
            color: isColorLight(content.author_avatar_color) ? '#000000' : '#FFFFFF'
          }}
        >
          {content.author[0].toUpperCase()}
        </div>
        <div>
          <h3 className="font-semibold text-gray-800">
            {content.author}
            <span className="font-thin text-gray-400 ml-1">#{content.author_digits}</span>
          </h3>
          <p className="text-sm text-gray-500">{formatTimestamp(content.timestamp)}</p>
        </div>
        {content.author === user.username && (
          <button
            onClick={() => setIsEditModalOpen(true)}
            className="ml-auto text-sm text-blue-500 hover:text-blue-700"
          >
            Edit
          </button>
        )}
      </div>
      {content.type === 'article' && (
        <>
          <h2 className={`text-xl font-semibold mb-2 text-gray-800 ${updatedFields.title ? 'animate-pulse' : ''}`}>
            {content.title}
          </h2>
          <div className={`mb-4 text-gray-600 ${updatedFields.content ? 'animate-pulse' : ''}`}>
            {renderContent(content.content)}
          </div>
        </>
      )}
      {content.type === 'tweet' && (
        <div className={`mb-4 text-gray-700 ${updatedFields.content ? 'animate-pulse' : ''}`}>
          {renderContent(content.content)}
        </div>
      )}
      {content.type === 'youtube' && (
        <>
          <h2 className={`text-xl font-semibold mb-2 text-gray-800 ${updatedFields.title ? 'animate-pulse' : ''}`}>
            {content.title}
          </h2>
          {content.content && (
            <div className={`mb-4 text-gray-600 ${updatedFields.content ? 'animate-pulse' : ''}`}>
              {renderContent(content.content)}
            </div>
          )}
        </>
      )}
      <div className="flex items-center space-x-4">
        <button
          onClick={handleLike}
          className="flex items-center space-x-1 text-gray-500 hover:text-blue-500 transition-colors duration-300"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
          </svg>
          <span>{likes}</span>
        </button>
        <button
          onClick={() => setShowComments(!showComments)}
          className="flex items-center space-x-1 text-gray-500 hover:text-blue-500 transition-colors duration-300"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
          </svg>
          <span>{content.comments}</span>
        </button>
      </div>
      {showComments && (
        <div className="mt-4 bg-gray-50 p-3 rounded">
          <p className="text-gray-600">Comments coming soon...</p>
        </div>
      )}
      {content.tags && content.tags.length > 0 && (
        <div className="mt-4 flex flex-wrap gap-2">
          {content.tags.map((tag, index) => (
            <span
              key={index}
              className={`px-2 py-1 rounded-full text-xs font-semibold text-white transition-all duration-300 ${
                newTags.includes(tag) ? 'animate-pulse scale-110' : ''
              }`}
              style={{ backgroundColor: getTagColor(tag) }}
            >
              {tag}
            </span>
          ))}
        </div>
      )}
      {isEditModalOpen && (
        <EditPostModal
          post={content}
          onClose={() => setIsEditModalOpen(false)}
          onUpdate={handlePostUpdate}
        />
      )}
    </div>
  );
}

export default ContentCard;