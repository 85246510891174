import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { API_BASE_URL } from '../config';

function FollowUser() {
  const [fullUsername, setFullUsername] = useState('');
  const [message, setMessage] = useState('');
  const [notFollowingUsers, setNotFollowingUsers] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    fetchNotFollowingUsers();
  }, []);

  const fetchNotFollowingUsers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/users`, {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setNotFollowingUsers(data.users);
      } else {
        console.error('Failed to fetch users');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await followUser(fullUsername);
  };

  const followUser = async (usernameToFollow) => {
    try {
      const response = await fetch(`${API_BASE_URL}/follow`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ fullUsername: usernameToFollow }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(`Successfully followed ${usernameToFollow}`);
        setFullUsername('');
        fetchNotFollowingUsers(); // Refresh the user list
      } else {
        setMessage(data.error || 'Failed to follow user');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Follow a User</h2>
      <form onSubmit={handleSubmit} className="space-y-4 mb-8">
        <div>
          <label htmlFor="fullUsername" className="block text-sm font-medium text-gray-700">
            Username#Digits
          </label>
          <input
            type="text"
            id="fullUsername"
            value={fullUsername}
            onChange={(e) => setFullUsername(e.target.value)}
            required
            placeholder="e.g., John#1234"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Follow User
        </button>
      </form>
      {message && (
        <p className="mt-4 text-center text-sm font-medium text-green-600">{message}</p>
      )}
      
      <h3 className="text-xl font-semibold mb-4">Users You Can Follow</h3>
      <ul className="space-y-2">
        {notFollowingUsers.map((userItem) => (
          <li key={userItem.id} className="flex justify-between items-center bg-white p-3 rounded-md shadow">
            <span>{userItem.fullUsername}</span>
            <button
              onClick={() => followUser(userItem.fullUsername)}
              className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded text-sm"
            >
              Follow
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FollowUser;