import React from 'react';

function TagFilter({ tags, selectedTags, onTagToggle }) {
  const getTagColor = (tagName) => {
    let hash = 0;
    for (let i = 0; i < tagName.length; i++) {
      hash = tagName.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 80%)`;
  };

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Discover by tags:</h3>
      <div className="flex flex-wrap gap-2">
        {tags.map((tag) => (
          <button
            key={tag}
            onClick={() => onTagToggle(tag)}
            className={`px-2 py-1 rounded-full text-xs font-semibold ${
              selectedTags.includes(tag) ? 'ring-2 ring-offset-2 ring-blue-500' : ''
            }`}
            style={{
              backgroundColor: getTagColor(tag),
              color: 'white',
            }}
          >
            {tag}
          </button>
        ))}
      </div>
    </div>
  );
}

export default TagFilter;