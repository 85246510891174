import React from 'react';
import StatusIndicator from './StatusIndicator';

function Footer() {
  return (
    <footer className="bg-white shadow-md mt-8">
      <div className="container mx-auto px-4 py-6 flex justify-between items-center">
        <p className="text-gray-600">&copy; 2024 WallShare. All rights reserved.</p>
        <StatusIndicator />
      </div>
    </footer>
  );
}

export default Footer;