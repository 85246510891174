import React from 'react';
import { Link } from 'react-router-dom';

function HowToUse() {
  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6">How to Use ShareWall</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Registration</h2>
        <p>To use ShareWall, you need to register through our Telegram bot:</p>
        <ol className="list-decimal list-inside ml-4 mt-2">
          <li>Open Telegram and search for <strong>@ShareWall</strong> bot</li>
          <li>Start a chat with the bot</li>
          <li>Send the <code>/start</code> command</li>
          <li>The bot will create your account and provide a verification code</li>
          <li>Use this code to log in on the website</li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Sharing Content and Tagging</h2>
        <p>To share content on ShareWall:</p>
        <ol className="list-decimal list-inside ml-4 mt-2">
          <li>Find an interesting article, tweet, or YouTube video</li>
          <li>Share the content with your native "Share" function of your device or send the URL manually to the @ShareWall Telegram bot</li>
          <li>While sending the URL, you can add tags by sending a comma-separated list of tags (e.g., "tech, news, ai") in the "Add a comment" field</li>
          <li>The bot will process the content, add the tags, and share it on your behalf</li>
          <li>The shared content with tags will appear in your followers' feeds</li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. Following Users</h2>
        <p>To follow other users and see their shared content:</p>
        <ol className="list-decimal list-inside ml-4 mt-2">
          <li>Go to the "Follow User" page</li>
          <li>Enter the full username of the user you want to follow (e.g., John#1234)</li>
          <li>Click the "Follow User" button</li>
          <li>You'll now see their shared content in your feed</li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Viewing Content</h2>
        <p>To view shared content:</p>
        <ol className="list-decimal list-inside ml-4 mt-2">
          <li>Log in to your account on the website</li>
          <li>The home page will show a feed of content shared by users you follow</li>
          <li>Scroll through the feed to see articles, tweets, and YouTube videos</li>
          <li>Click on links to view the full content on the original platform</li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Using Tags</h2>
        <p>To filter content using tags:</p>
        <ol className="list-decimal list-inside ml-4 mt-2">
          <li>Look for the "Discover Your Interests" section at the top of your feed</li>
          <li>Click on one or more tags to filter the content</li>
          <li>The feed will update to show only content with the selected tags</li>
          <li>Click on a selected tag again to remove it from the filter</li>
        </ol>
      </section>

      <p className="mt-8">
        For more information or if you need help, please contact our support team through the Telegram bot.
      </p>

      <Link to="/" className="block mt-8 text-blue-500 hover:underline">
        Back to Home
      </Link>
    </div>
  );
}

export default HowToUse;