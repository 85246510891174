import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';

function LinkPreview({ url }) {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPreview = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/link-preview?url=${encodeURIComponent(url)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch preview');
        }
        const data = await response.json();
        setPreview(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPreview();
  }, [url]);

  if (loading) return <div className="mt-2 text-gray-500">Loading preview...</div>;
  if (error) return <div className="mt-2 text-red-500">Error loading preview: {error}</div>;
  if (!preview) return null;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className="block mt-2 border rounded-lg overflow-hidden hover:shadow-md transition-shadow duration-200">
      {preview.image && (
        <img src={preview.image} alt={preview.title} className="w-full h-32 object-cover" />
      )}
      <div className="p-4">
        <h3 className="font-semibold text-lg text-blue-600">{preview.title}</h3>
        {preview.description && (
          <p className="text-gray-600 text-sm mt-1">{preview.description}</p>
        )}
        <p className="text-gray-400 text-xs mt-2">{new URL(url).hostname}</p>
      </div>
    </a>
  );
}

export default LinkPreview;
