import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import ContentFeed from './components/ContentFeed';
import Footer from './components/Footer';
import Login from './components/Login';
import Register from './components/Register';
import { AuthProvider, useAuth } from './AuthContext';
import FollowUser from './components/FollowUser';
import FollowLists from './components/FollowLists';
import HowToUse from './components/HowToUse';
import ProfilePage from './components/ProfilePage';
import NotFound from './components/NotFound';



const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  
  if (user === undefined) {
    // Still checking authentication
    return <div>Loading...</div>;
  }
  
  if (user === null) {
    // Not authenticated
    return <Navigate to="/login" replace />;
  }
  
  // Authenticated
  return children;
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="flex flex-col min-h-screen bg-gray-100">
          <Header />
          <main className="flex-grow container mx-auto px-4 py-8 sm:px-6 lg:px-8">
            <Routes>
              <Route path="/" element={<ProtectedRoute><ContentFeed /></ProtectedRoute>} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/follow" element={<ProtectedRoute><FollowUser /></ProtectedRoute>} />
              <Route path="/follow-lists" element={<ProtectedRoute><FollowLists /></ProtectedRoute>} />
              <Route path="/how-to-use" element={<HowToUse />} />
              <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
