import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

function Header() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    const success = await logout();
    if (success) {
      navigate('/login');
    }
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-blue-600 text-white">
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <Link to="/" className="text-2xl font-bold">WallShare</Link>
            {user && <span className="text-sm md:text-base">Welcome, {user.username}!</span>}
          </div>
          
          {/* Burger menu icon */}
          <button 
            className="md:hidden"
            onClick={toggleMenu}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>

          {/* Desktop menu */}
          <nav className="hidden md:block">
            <ul className="flex space-x-4 items-center">
              {user ? (
                <>
                  <li><Link to="/how-to-use" className="hover:text-blue-200">How to Use</Link></li>
                  <li><Link to="/follow" className="hover:text-blue-200">Follow User</Link></li>
                  <li><Link to="/follow-lists" className="hover:text-blue-200">Follow Lists</Link></li>
                  <li><Link to="/profile" className="hover:text-blue-200">Profile</Link></li>
                  <li>
                    <button 
                      onClick={handleLogout} 
                      className="bg-red-500 hover:bg-red-600 px-3 py-1 rounded"
                    >
                      Logout
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li><Link to="/how-to-use" className="hover:text-blue-200">How to Use</Link></li>
                  <li><Link to="/login" className="hover:text-blue-200">Login</Link></li>
                  <li><Link to="/register" className="hover:text-blue-200">Register</Link></li>
                </>
              )}
            </ul>
          </nav>
        </div>

        {/* Mobile menu */}
        <nav className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'} mt-4`}>
          <ul className="flex flex-col space-y-2">
            {user ? (
              <>
                <li><Link to="/" className="block hover:text-blue-200" onClick={() => setIsMenuOpen(false)}>Home</Link></li>
                <li><Link to="/profile" className="block hover:text-blue-200" onClick={() => setIsMenuOpen(false)}>Profile</Link></li>
                <li><Link to="/follow" className="block hover:text-blue-200" onClick={() => setIsMenuOpen(false)}>Follow User</Link></li>
                <li><Link to="/follow-lists" className="block hover:text-blue-200" onClick={() => setIsMenuOpen(false)}>Follow Lists</Link></li>
                <li>
                  <button 
                    onClick={handleLogout} 
                    className="bg-red-500 hover:bg-red-600 px-3 py-1 rounded w-full text-left"
                  >
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <>
                <li><Link to="/how-to-use" className="block hover:text-blue-200" onClick={() => setIsMenuOpen(false)}>How to Use</Link></li>
                <li><Link to="/login" className="block hover:text-blue-200" onClick={() => setIsMenuOpen(false)}>Login</Link></li>
                <li><Link to="/register" className="block hover:text-blue-200" onClick={() => setIsMenuOpen(false)}>Register</Link></li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;