import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';

function StatusIndicator() {
  const [backendStatus, setBackendStatus] = useState('unknown');
  const [telegramBotStatus, setTelegramBotStatus] = useState('unknown');

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const backendResponse = await fetch(`${API_BASE_URL}/health`);
        setBackendStatus(backendResponse.ok ? 'online' : 'offline');
      } catch (error) {
        setBackendStatus('offline');
      }

      try {
        const telegramResponse = await fetch(`${API_BASE_URL}/telegram/health`);
        setTelegramBotStatus(telegramResponse.ok ? 'online' : 'offline');
      } catch (error) {
        setTelegramBotStatus('offline');
      }
    };

    checkStatus();
    const interval = setInterval(checkStatus, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'online':
        return 'bg-green-500';
      case 'offline':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="flex items-center">
        <span className="mr-2 text-sm">Backend:</span>
        <div className={`w-3 h-3 rounded-full ${getStatusColor(backendStatus)}`}></div>
      </div>
      <div className="flex items-center">
        <span className="mr-2 text-sm">Telegram Bot:</span>
        <div className={`w-3 h-3 rounded-full ${getStatusColor(telegramBotStatus)}`}></div>
      </div>
    </div>
  );
}

export default StatusIndicator;