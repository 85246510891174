import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';
import { FaSun, FaCalendarWeek, FaCalendarAlt } from 'react-icons/fa';

function EmailSummary({ email, user }) {
  const [selectedPeriod, setSelectedPeriod] = useState('day');
  const [message, setMessage] = useState('');
  const [currentEmail, setCurrentEmail] = useState(email);

  useEffect(() => {
    setCurrentEmail(email);
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentEmail) {
      setMessage('Please add your email in "My Profile" first.');
      return;
    }
    
    if (!selectedPeriod) {
      setMessage('Please select a period.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/email-summary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ period: selectedPeriod, email: currentEmail }),
      });

      const data = await response.json();
      console.log(response);

      if (response.ok) {
        setMessage('Email summary sent successfully!');
      } else {
        setMessage(data.error || 'Failed to send email summary');
      }
    } catch (error) {
      console.log(error);
      setMessage('An error occurred. Please try again.');
    }
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex space-x-4">
          {[
            { key: 'day', label: 'Day', icon: FaSun },
            { key: 'week', label: 'Week', icon: FaCalendarWeek },
            { key: 'month', label: 'Month', icon: FaCalendarAlt },
          ].map(({ key, label, icon: Icon }) => (
            <label key={key} className="flex items-center space-x-2 cursor-pointer">
              <input
                type="radio"
                checked={selectedPeriod === key}
                onChange={() => handlePeriodChange(key)}
                className="form-radio h-5 w-5 text-blue-600"
              />
              <Icon className="h-6 w-6 text-gray-500" />
              <span className="text-sm font-medium text-gray-700">{label}</span>
            </label>
          ))}
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Send Email Summary
        </button>
      </form>
      {message && (
        <p className="mt-4 text-sm font-medium text-green-600">{message}</p>
      )}
    </>
  );
}

export default EmailSummary;