import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const funnyMessages = [
  "Oops! Looks like this page took a wrong turn at Albuquerque.",
  "404: Page not found. Maybe it's on a coffee break?",
  "This page is playing hide and seek. It's winning.",
  "Congratulations! You've reached the end of the internet.",
  "Error 404: Page not found. But on the bright side, you found our error page!",
  "The page you're looking for is on vacation. Try again when it returns.",
  "This page has gone to explore the world. Bon voyage!",
  "404: Page not found. It probably got lost in the cloud.",
  "Oops! Our intern must have spilled coffee on this page.",
  "This page is experiencing an existential crisis. Please try again later."
];

function NotFound() {
  const [funnyMessage, setFunnyMessage] = useState('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * funnyMessages.length);
    setFunnyMessage(funnyMessages[randomIndex]);
  }, []);

  return (
    <div className="flex flex-col items-center justify-start bg-gray-100 px-4 sm:px-6 lg:px-8 pt-16">
      <div className="max-w-md w-full space-y-6 text-center">
        <h1 className="text-8xl font-extrabold text-blue-600">404</h1>
        <h2 className="mt-4 text-3xl font-bold text-gray-900">
          Page Not Found
        </h2>
        <p className="mt-2 text-base text-gray-600">
          {funnyMessage}
        </p>
        <div className="mt-6">
          <Link
            to="/"
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Go back home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;