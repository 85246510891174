import React from 'react';
import { Link } from 'react-router-dom';

function Register() {
  return (
    <div className="max-w-md mx-auto mt-8 text-center">
      <h2 className="text-2xl font-bold mb-4">Register for ShareWall</h2>
      <p className="mb-4">
        To register for ShareWall, please follow these steps:
      </p>
      <ol className="list-decimal text-left pl-6 mb-6">
        <li>Open Telegram and search for our bot: <strong>@ShareWall</strong></li>
        <li>Start a chat with the bot</li>
        <li>Send the <code>/start</code> command in the chat</li>
        <li>The bot will automatically create your account using your Telegram first name and a unique 4-digit code (e.g., John#1234)</li>
        <li>You'll receive a verification code immediately after account creation</li>
        <li>Use this verification code to log in on the website</li>
      </ol>
      <p className="mb-4">
        If you're already registered and need a new verification code, you can use the <code>/verify</code> command in the Telegram chat at any time.
      </p>
      <p className="mb-4">
        Once you've completed these steps, you can log in using your full username (including the #1234 part) and the verification code.
      </p>
      <Link to="/login" className="text-blue-500 hover:underline">
        Go to Login Page
      </Link>
    </div>
  );
}

export default Register;